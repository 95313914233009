<template>
  <div>
    <div class="slider-category-title">{{$t(title)}}</div>

  <div v-if="castncrew.length > 0" class="">


      <div class="cast-details" v-for="(item, i) in castncrew" :key="i" >
           <img :src="item.profilepic ?  item.profilepic : placeholder_port" width="60px" height="60px" style="border-radius: 50%;"/>
          <div class="cast-name"><p>{{item.name }} <span v-if="item.role.length > 0">({{ item.role.join(" ,")}}) <span style="margin-left: 0.3rem;"> </span> </span></p></div>
 

    </div>


  </div> 
  <div v-else class="cast-details cast-name">
     <p class="no-found">{{$t("No Data Found")}}</p>
  </div>
</div>
</template>

<script>
export default {
  props: ['id', 'title','castncrew'],
  data() {
    return {
      selected: false,
      placeholder_port: require('@/assets/user.png'),

    };
  },
  methods: {

  },

};
</script>



<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.cast-name {
  font-weight: bold;
  color: #999;
  margin-top: 1rem;
 }
 .cast-details {
  display: flex;
  align-items: normal;
  gap: 0.5rem;
  margin: 1rem 0rem;
 }
 .no-found {
  margin-top: 1rem;

 }
 .slider-category-title {
  font-size: 1.1rem;
  font-weight: bold;
 }
</style>
